import React from 'react'
import Layout from '../components/Layout/Layout'

const CancellationAndRefund = () => {
  return (
    <Layout>
      <h1>Cancellation and Refund</h1>
    </Layout>
  )
}

export default CancellationAndRefund
