import React from "react";

const DashboardCatalogue = () => {
  return (
    <div>
      <h1>Dashboard Catalogue </h1>
    </div>
  );
};

export default DashboardCatalogue;
