import React from 'react'
import Layout from '../components/Layout/Layout'

const PrivacyPolicy = () => {
  return (
    <Layout>
      <h1>Privacy Policy</h1>
    </Layout>
  )
}

export default PrivacyPolicy
