import React from "react";
import Layout from "../components/Layout/Layout";

const TermsOfServices = () => {
  return (
    <Layout>
      <h1>Terms of Services</h1>
    </Layout>
  );
};

export default TermsOfServices;
