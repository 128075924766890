import React from "react";
import Layout from "../../components/Layout/Layout";

const MyCourses = () => {
  return (
    <Layout>
      <h1>My Courses</h1>
    </Layout>
  );
};

export default MyCourses;
